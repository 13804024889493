<template>
  <div>
    <div class="cand-info-img text-center m-auto pb-1">
      <img
        :src="generateFullUrlWithToken(profile.profile_picture)"
        class="rounded-circle"
        @error="$event.target.src = '/img/user_default_pic.png'"
        alt
      />
    </div>
    <div class="text-center">
      <h5 class="m-0" :style="`color: ${getGenderColor(profile.gender)}`">
        {{ profile._title | toTitleCase }}
        {{ profile.first_name | toTitleCase }}
        {{ profile.surname | toTitleCase }}
      </h5>
      <p class="mb-0" v-if="profile.candidateTypeName">
        {{ profile.candidateTypeName }}
      </p>
      <p class="mb-1">
        <span v-if="profile.nationalityName">
          {{ profile.nationalityName }}
        </span>
        <span v-if="profile.genderName">, {{ profile.genderName }}</span>
        <span class="nowrap" v-if="profile.age">, {{ profile.age }} Years</span>
      </p>
      <div class="pb-2">
        <span class="text-muted">Candidate ID : </span>
        <span class="font-weight-bold">{{ profile.display_uid }}</span>
      </div>
      <div class="pb-2" v-show="profile.original_ohr_id != null">
        <span class="text-muted">Old CRM ID : </span
        ><span class="font-weight-bold">{{ profile.original_ohr_id }}</span>
      </div>
      <div class="mb-2" v-if="!isUKMainAgency && isOrgBadge && !isAgencyBadge">
        <CBadge color="danger" class="p-1"
          >{{ profile.organisation_name }}
        </CBadge>
        <span class="three-dots mx-1"
        v-c-tooltip="{
          content: toolTip,
          appendToBody: true,
          placement: 'right',
        }">
          <i class="fa-solid fa-ellipsis-vertical"></i>
        </span>
      </div>
      <div
        class="pb-1"
        v-if="
          currentUserRole !== Role.systemCandidate &&
          currentUserRole !== Role.customerCandidate &&
          !isCandidateSupplierFromAccessToken
        "
      >
        <span class="text-primary" :style="'font-size: 12px'"
          >Registered: {{ profile.createdOnDate }}
        </span>
      </div>
      <div
        class="pb-2"
        v-if="
          currentUserRole !== Role.systemCandidate &&
          currentUserRole !== Role.customerCandidate &&
          !isCandidateSupplierFromAccessToken
        "
      >
        <span class="text-primary" :style="'font-size: 12px'"
          >Last Login: {{ profile.lastLoginDate }}
        </span>
      </div>
      <CRow class="justify-content-center">
        <a v-if="latestResume && !isJobSupplier && !isHeadlessClient">
          <CButton
            color="primary"
            class="px-2 f-12 mr-2"
            @click="preview(latestResume)"
          >
            {{ latestResume.lable }}
            <i class="fas fa-eye ml-2"></i>
          </CButton>
        </a>
        <a v-if="latestAgencyCV && isJobSupplier">
          <CButton
            color="primary"
            class="px-2 f-12 mr-2"
            @click="preview(latestAgencyCV)"
          >
            {{ latestAgencyCV.lable }}
            <i class="fas fa-eye ml-2"></i>
          </CButton>
        </a>
        <CButton
          v-else-if="isJobSupplier && !latestAgencyCV"
          name="btn_req_agency_cv"
          color="primary"
          class="px-2 f-12 mr-2"
          @click="openReqAgencyModal"
          :disabled="isAgencyCVReqPending"
        >
          Request Agency CV
        </CButton>
        <CButton
          v-if="!isCandidateRole && !isCandidateSupplierFromAccessToken"
          color="primary"
          class="px-2 f-12"
          @click="openFrontSheet"
        >
          Frontsheet
          <i class="fas fa-download ml-2"></i>
        </CButton>
      </CRow>
      <CRow
        v-if="currentUserRole === Role.systemAdmin"
        class="mt-2 justify-content-center"
      >
        <CButton
          v-if="!profile.deleted"
          name="Delete"
          color="primary"
          class="px-2 f-12 col-4"
          @click="candidateState($event)"
          >Delete</CButton
        >
        <CButton
          v-if="profile.deleted"
          name="Restore"
          color="primary"
          class="px-2 f-12 col-4"
          @click="candidateState($event)"
          >Restore</CButton
        >
        <CButton
          v-if="
            (profile.available_during_covid ||
              profile.available_during_covid === null) &&
            !profile.deleted
          "
          name="Deactivate"
          variant="outline"
          color="primary"
          class="ml-2 f-12 px-1 col-4"
          @click="candidateState($event)"
          >Deactivate</CButton
        >
        <CButton
          v-if="profile.available_during_covid === false && !profile.deleted"
          name="Reactivate"
          variant="outline"
          color="primary"
          class="ml-2 px-1 f-12 col-4"
          @click="candidateState($event)"
          >Reactivate</CButton
        >
      </CRow>
    </div>
    <div class="position-absolute" style="top: 10px; left: 15px" v-if="isPrivate">
      <i class="fs-14 fa-solid fa-triangle-exclamation text-primary" v-c-tooltip="{
          content: 'Private Profile',
          appendToBody: true,
          placement: 'right',
        }"></i>
    </div>
    <div class="position-absolute" style="top: 10px; right: 15px" v-if="enableAddScratchPad">
      <span
        class="text-primary cursor-pointer"
        v-c-tooltip="{
          content: 'Add to Scratch Pad',
          appendToBody: true,
          placement: 'left',
        }"
      >
        <i class="fa-solid fa-circle-plus fs-14" @click="addToScratch"></i>
      </span>
    </div>
    
    <div class="position-absolute" style="top: 10px; left: 15px" v-if="inProcessCount">
      <span
        class="text-primary font-weight-bold border-circle border-primary"
        v-c-tooltip="{
          content: 'In Process',
          appendToBody: true,
          placement: 'left',
        }"
      >
        P
      </span>
    </div>
    <PopupModal
      v-if="popupModal.isShowPopup"
      :modalTitle="popupModal.modalTitle"
      :modalColor="popupModal.modalColor"
      :modalContent="popupModal.modalContent"
      :modalLabel="popupModal.modalLabel"
      :isShowPopup="popupModal.isShowPopup"
      :popupModalCallBack="popupModalCallBack"
      :modalJob="popupModal.modalJob"
      :modalReasonType="popupModal.modalReasonType"
    />
    <PreviewModal
      v-if="previewModal.isShowPopup"
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
    <CandidateFrontsheet
      v-if="frontSheetModal.isShowPopup"
      :isShowPopup="frontSheetModal.isShowPopup"
      @modalCallBack="frontSheetModalCallBack"
      :candidateDetail="frontSheetModal.candidate"
      :jobDetail="frontSheetModal.job"
      :frontSheetInfo="frontSheetModal.frontSheetInfo"
      :frontSheets="frontSheetModal.comments"
    />
    <Modal
      v-if="reqAgencyModal.isShowPopup"
      :modalTitle="reqAgencyModal.modalTitle"
      :modalColor="reqAgencyModal.modalColor"
      :modalContent="reqAgencyModal.modalContent"
      :isShowPopup="reqAgencyModal.isShowPopup"
      :buttons="reqAgencyModal.buttons"
      :modalCallBack="reqAgencyModalCallBack"
      :closeOnBackdrop="false"
    />
    <ScratchPadModal
      v-if="scratchpad.isShowPopup"
      :todo="scratchpad.todo"
      :isShowPopup="scratchpad.isShowPopup"
      @modalCallBack="scratchPadModalCallBack"
    />
  </div>
</template>
<script>
import _ from "lodash";
import { getScope, Role, generateFullUrlWithToken  } from "@/helpers/helper"; 
import { mapActions, mapGetters } from "vuex";
import pdf from "vue-pdf";
import { BASE_URL } from "@/service_urls";
import isJobSupplier from "@/mixins/isJobSupplier";
import PreviewModal from "@/components/reusable/PreviewModal";
import PopupModal from "@/components/reusable/PopupModal";
import Modal from "@/components/reusable/Modal";
import CandidateFrontsheet from "@/containers/Frontsheet/CandidateFrontsheet";
import ScratchPadModal from "@/containers/Dashboard/RecruiterDashBoard/ScratchPadModal";

export default {
  mixins: [isJobSupplier],
  props: {
    profile: {
      type: Object,
      default: {},
    },
    agencyCVDocument: {
      type: Array,
      default: () => [],
    },
    resumeDocument: {
      type: Array,
      default: () => [],
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    enableAddScratchPad: {
      type: Boolean,
      default: true,
    }
  },
  components: {
    Modal,
    PreviewModal,
    PopupModal,
    CandidateFrontsheet,
    ScratchPadModal,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      frontSheetModal: {
        isShowPopup: false,
        candidate: null,
        job: null,
        frontSheetInfo: null,
        comments: [],
      },
      reqAgencyModal: {
        isShowPopup: false,
        modalTitle: "Request Agency CV",
        modalContent:
          "Please confirm you would like to request the CV for this candidate?",
        modalColor: "primary",
        buttons: ["No", "Yes"],
      },
      popupModal: {
        modalColor: "",
        modalTitle: "",
        modalContent: "",
        modalLabel: "Select Reason Code",
        isShowPopup: false,
        modalJob: "",
        modalReasonType: "",
      },
      scratchpad: {
        isShowPopup: false,
        toDo: {},
      },
      generateFullUrlWithToken,
    };
  },
  computed: {
    ...mapGetters([
      "isCandidateSupplierFromAccessToken",
      "getGenderColor",
      "getUserId",
      "getOrgIDFromAccessToken",
      "getAgencyCVRequest",
      "getCustomerId",
      "getUserId",
      "getIsParentUser",
      "isUKMainAgency",
      "isHeadlessClient",
      "getJobCandidateCount",
    ]),
    isPrivate(){
      return this.profile?.is_private || false;
    },
    isAgencyBadge() {
      return (
        (this.profile.agency_contact_id &&
          this.profile.organisation_name &&
          this.isUKMainAgency &&
          this.currentUserRole !== this.Role.systemAdmin) ||
        false
      );
    },
    isOrgBadge() {
      return (
        (this.profile.organisation_id != this.getOrgIDFromAccessToken &&
          this.getIsParentUser) ||
        false
      );
    },
    isAgencyCVReqPending() {
      return (
        this.getAgencyCVRequest.filter(
          ({ candidate_id, date_uploaded, req_org_id }) =>
            candidate_id === this.profile?.candidate_uid &&
            date_uploaded == null &&
            req_org_id === this.getOrgIDFromAccessToken
        ).length > 0 || false
      );
    },
    isCandidateRole() {
      return (
        this.currentUserRole === Role.customerCandidate ||
        this.currentUserRole === Role.systemCandidate
      );
    },
    latestResume() {
      return _.maxBy(this.resumeDocument, "version_no");
    },
    latestAgencyCV() {
      return _.maxBy(this.agencyCVDocument, "version_no");
    },
    inProcessCount(){
      return this.getJobCandidateCount && this.getJobCandidateCount[4] || null
    },
    isShowPrcessBadge(){
      return !this.isCandidateRole && !this.isCustomerUserRole;
    },    
    isCustomerUserRole() {
      return this.currentUserRole === Role.customerUser;
    },
    toolTip(){
    return this.profile.shared_organisations.map((i) => i.name).join(', ')
    },
  },
  methods: {
    ...mapActions([
      "createAgencyCVRequest",
      "postScratchPad",
      "deleteCandidate",
      "deactivateCandidate",
      "restoreCandidate",
      "reactivateCandidate",
      "getCandidateDetails",
    ]),
    showPreview(data) {
      const { link } = data;
        // const _baseUrl = BASE_URL;
        // const _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
        return generateFullUrlWithToken(link);;
    },
    preview(data) {
      data.link_to_document = this.showPreview(data);
      var _baseUrl = BASE_URL;
      var _base_domain = "https://" + window.location.host;
      this.iframe.url =  data.link_to_document;
      if (["PNG", "JPG", "JPEG", "GIF"].includes(data?.document_ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = data.link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (data?.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(data.link_to_document);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        ["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(data?.document_ext)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          data.link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    openFrontSheet() {
      this.frontSheetModal.candidate = this.profile;
      this.frontSheetModal.isShowPopup = true;
      this.frontSheetModal.comments = [];
    },
    frontSheetModalCallBack(action, payload) {
      this.frontSheetModal.isShowPopup = false;
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    candidateState(event) {
      let name = event.currentTarget.name;
      let cname = {
        delete: "Delete",
        deactivate: "Deactivate",
      };
      this.popupModal.modalReasonType = name.toUpperCase();
      this.popupModal.modalTitle = `${name} Candidate`;
      this.popupModal.modalContent = `Are you sure to ${name.toLowerCase()} the candidate? If Yes please select an appropriate reason..`;
      this.popupModal.isShowPopup = true;
      if (name === cname.delete || name === cname.deactivate) {
        this.popupModal.modalColor = "danger";
      } else {
        this.popupModal.modalColor = "primary";
      }
    },
    popupModalCallBack(action, value) {
      let appendAction;
      const { modalTitle } = this.popupModal;
      const _action = {
        confirm: "Confirm",
        cancel: "Cancel",
        deleteCandidate: "Delete Candidate",
        deactivateCandidate: "Deactivate Candidate",
        restoreCandidate: "Restore Candidate",
        reactivateCandidate: "Reactivate Candidate",
      };
      if (action === _action.confirm) {
        let payload = {
          candidate_id: this.profile.candidate_uid,
          reason_id: value.reason_id,
          comments: value.comments,
          customer_uid: this.profile?.customer_uid,
        };
        //Delete
        if (modalTitle === _action.deleteCandidate) {
          appendAction = this.deleteCandidate(payload);
        }
        //Deactivate
        else if (modalTitle === _action.deactivateCandidate) {
          appendAction = this.deactivateCandidate(payload);
        }
        //Restore
        else if (modalTitle === _action.restoreCandidate) {
          appendAction = this.restoreCandidate(payload);
        }
        //Reactivate
        else if (modalTitle === _action.reactivateCandidate) {
          appendAction = this.reactivateCandidate(payload);
        }
        Promise.all([appendAction]).then((res) => {
          this.getCandidateDetails(this.$route.params.id);
        });
      }
      this.popupModal.isShowPopup = false;
    },
    openReqAgencyModal() {
      this.reqAgencyModal.isShowPopup = true;
      // this.reqAgencyModal.modalContent = `Please confirm you would like to request the CV for candidate - ${this.profile.full_name}?`
    },
    reqAgencyModalCallBack(action) {
      if (action === "Yes") {
        //Request Agency CV
        const payload = {
          req_org_id: this.getOrgIDFromAccessToken,
          req_user_id: this.getUserId,
          candidate_id: this.profile?.candidate_uid,
        };
        this.createAgencyCVRequest(payload);
      }
      this.reqAgencyModal["isShowPopup"] = false;
    },
    addToScratch() {
      const name = `<a href='/candidate/${
        this.profile.candidate_uid
      }'>${this.toTitleCase(this.profile.first_name)} ${this.toTitleCase(
        this.profile.surname
      )} (ID-${this.profile.display_uid})</a>`;
      this.scratchpad.todo = {
        title: `Candidate - ${this.toTitleCase(
          this.profile.first_name
        )} ${this.toTitleCase(this.profile.surname)} (ID-${
          this.profile.display_uid
        })`,
        description: `<p>Please review candidate ${name}</p>`,
      };
      this.scratchpad.isShowPopup = true;
    },
    scratchPadModalCallBack(action, payload) {
      if (action && payload) {
        this.postScratchPad(payload);
      }
      this.scratchpad.isShowPopup = false;
      this.scratchpad.todo = null;
    },
    toTitleCase(str) {
      if (str) return _.startCase(str);
      return str;
    },
  },
  filters: {
    toTitleCase(str) {
      if (str) return _.startCase(str);
      return str;
    },
  },
};
</script>
<style lang="scss" scoped>
.fs-14 {
  font-size: 14px !important;
}
</style>